<template>
    <div id="MemberOverview" class="overview-style">
        <section class="filter-section">
            <ElForm
                :label-position="store.getters.labelPosition"
                label-width="90px"
                :model="memberFilter"
            >
                <div class="left">
                    <ElFormItem label="検索">
                        <ElInput
                            v-model.lazy="memberFilter.keyword"
                            @change="
                                updateQuery([['keyword', memberFilter.keyword]])
                            "
                            @keypress.enter="
                                updateQuery([['keyword', memberFilter.keyword]])
                            "
                            @submit.prevent
                        ></ElInput>
                    </ElFormItem>
                    <ElFormItem label="フィルター" class="closer">
                        <ElCheckbox
                            v-model="memberFilter.baseCategory"
                            label="基本セミナー"
                            @change="
                                updateQuery([
                                    ['baseCategory', memberFilter.baseCategory],
                                ])
                            "
                        ></ElCheckbox>
                    </ElFormItem>
                    <ElFormItem
                        ><ElCheckboxGroup
                            v-model="memberFilter.theologyCategory"
                            @change="
                                updateQuery([
                                    [
                                        'theologyCategory',
                                        memberFilter.theologyCategory,
                                    ],
                                ])
                            "
                        >
                            <ElCheckbox
                                v-for="item in store.getters
                                    .getTheologySubcategory"
                                :key="item.subCategory"
                                :label="item.subCategory"
                            ></ElCheckbox></ElCheckboxGroup
                    ></ElFormItem>
                </div>
                <div class="right">
                    <ElFormItem label="ソート">
                        <ElSelect
                            v-model="memberFilter.orderStr"
                            @change="
                                updateQuery([
                                    ['orderStr', memberFilter.orderStr],
                                ])
                            "
                        >
                            <ElOption
                                v-for="item in sortOptions"
                                :key="item.label"
                                :label="item.label"
                                :value="item.value"
                            ></ElOption>
                        </ElSelect>
                    </ElFormItem>
                    <ElFormItem label="エクスポート" label-width="120px">
                        <div class="button-wrap">
                            <button
                                class="f18 f16-mb"
                                @click.prevent="downLoadMemberList"
                            >
                                CSV出力
                            </button>
                        </div>
                        <ElCheckbox
                            v-model="exportCondRegistInfo"
                            label="界員登録情報も出力"
                            @change="toggleExportCondRegistInfo"
                        ></ElCheckbox>
                    </ElFormItem>
                </div>
            </ElForm>
        </section>
        <section class="table-section">
            <ElTable
                :data="
                    memberList.slice(
                        (currentPage - 1) * pageSize,
                        currentPage * pageSize
                    )
                "
                style="width: 100%; font-size: 12px"
            >
                <ElTableColumn label="名前" fixed>
                    <template #default="scope">
                        <router-link
                            :to="{
                                name: 'MemberDetails',
                                params: { userId: scope.row.userId },
                            }"
                        >
                            {{ scope.row.name }}
                        </router-link>
                    </template>
                </ElTableColumn>
                <ElTableColumn
                    prop="furigana"
                    label="ふりがな"
                    fixed
                ></ElTableColumn>
                <ElTableColumn
                    prop="createdAt"
                    label="登録日"
                    fixed
                ></ElTableColumn>
                <ElTableColumn
                    prop="qualification"
                    label="真資格"
                    fixed
                ></ElTableColumn>
                <ElTableColumn
                    prop="role"
                    label="担当真使"
                    fixed
                ></ElTableColumn>
                <ElTableColumn
                    v-for="item in courseList"
                    :key="item"
                    :label="item"
                    width="80"
                >
                    <template #default="scope">
                        <span v-if="scope.row.courseRecords[item]"> V </span>
                    </template>
                </ElTableColumn>
            </ElTable>
        </section>
        <section class="pagination-section">
            <ElPagination
                v-model:currentPage="currentPage"
                v-model:page-size="pageSize"
                :page-sizes="[10, 15, 20, 30]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="memberList.length"
            />
        </section>
    </div>
</template>
<script>
export default {
    name: 'MemberOverview',
}
</script>
<script setup>
import { reactive, ref, onMounted, computed } from 'vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getMemberOverview } from '@/service/api'
import { initQuery, updateQuery } from '@/utils/query'
const store = useStore()
const route = useRoute()

onBeforeRouteUpdate((to) => {
    initMemberList(to.query)
})
onMounted(() => {
    initQuery(memberFilter)
})
initMemberList(route.query)
const currentPage = ref(1)
const pageSize = ref(10)
const courseList = computed(() => {
    let arr = ['基本セミナー']
    for (let item of store.getters.getTheologySubcategory) {
        arr.push(item.subCategory)
    }
    return arr
})
const sortOptions = [
    { value: 'createdAt,desc', label: '登録日' },
    { value: 'createdAt,asc', label: '登録日昇順' },
    { value: 'qualification,asc', label: '真資格 A-Z' },
    { value: 'qualification,desc', label: '真資格 Z-A' },
    { value: 'role,asc', label: '担当真使 A-Z' },
    { value: 'role,desc', label: '担当真使 Z-A' },
]
const memberFilter = reactive({
    keyword: '',
    baseCategory: false,
    theologyCategory: [],
    orderStr: '',
})
const memberList = ref([])
const exportCondRegistInfo = ref(true)
const toggleExportCondRegistInfo = () => {
    exportCondRegistInfo.value ? false : true
}
async function initMemberList(query) {
    let res = await getMemberOverview(query)
    memberList.value = res.data
}
async function downLoadMemberList() {
    const BOM = new Uint8Array([0xef, 0xbb, 0xbf])
    const SEP = ','
    const LF = '\n'
    const titleList = ['名前', 'ふりがな', '登録日', '真資格', '担当真使']
    const titleRegistInfo = [
        'Id',
        '基本セミナー修了 神学科の履修権限',
        'メールアドレス',
        '生年月日',
        '電話番号',
        '住所',
        '職業',
        '親育番号',
        '紹介者',
        '所属親交所',
    ]

    let header = ''
    titleList.forEach((title) => {
        header += header.length === 0 ? '' : SEP
        header += title
    })
    Object.keys(courseList.value).forEach((key) => {
        header += SEP + courseList.value[key]
    })
    if (exportCondRegistInfo.value) {
        titleRegistInfo.forEach((title) => {
            header += SEP + title
        })
    }
    let csv = header + LF

    memberList.value.forEach((member) => {
        let row =
            member['name'] +
            SEP +
            member['furigana'] +
            SEP +
            member['createdAt'] +
            SEP +
            member['qualification'] +
            SEP +
            member['role']
        Object.keys(member['courseRecords']).forEach((key) => {
            row += SEP + member['courseRecords'][key]
        })
        if (exportCondRegistInfo.value) {
            row += SEP + member['userId']
            row += SEP
            row += parseInt(member['level']) > 2 ? '許可' : '不許可'
            row +=
                SEP +
                member['email'] +
                SEP +
                member['birth'] +
                SEP +
                member['phone'] +
                SEP +
                member['address'] +
                SEP +
                member['job'] +
                SEP +
                member['memberId'] +
                SEP +
                member['recommender'] +
                SEP +
                member['location']
        }

        csv += row + LF
    })
    let blob = new Blob([BOM, csv], { type: 'text/csv' })
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'memberList.csv'
    link.click()
}
</script>
<style lang="scss" scoped>
.button-wrap {
    flex: 0 0 150px;
    margin: 0 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: normal;
    button {
        @include solidButtonStyle();
    }
}
</style>
