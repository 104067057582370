import router from '@/router/index.js'

export function initQuery(form) {
    let query = router.currentRoute.value.query
    for (let key of Object.keys(query)) {
        if (key === 'order') continue
        else if (key === 'orderKey') {
            form.orderStr = query.orderKey + ',' + query.order
        } else if (Array.isArray(form[key])) {
            form[key] = query[key].split('|')
        } else {
            form[key] = query[key]
        }
    }
}
export function updateQuery(pairArr) {
    let query = router.currentRoute.value.query
    let newQuery = {}
    for (let key of Object.keys(query)) {
        newQuery[key] = query[key]
    }
    for (let [key, value] of pairArr) {
        if (key === 'orderStr') {
            if (!value) delete newQuery.order, delete newQuery.orderKey
            else {
                newQuery.orderKey = value.split(',')[0]
                newQuery.order = value.split(',')[1]
            }
        } else if (Array.isArray(value)) {
            if (value.length === 0) delete newQuery[key]
            else newQuery[key] = value.join('|')
        } else {
            if (value === '') delete newQuery[key]
            else newQuery[key] = value
        }
    }

    router.push({ path: router.currentRoute.value.path, query: newQuery })
}
